import React from 'react';
import ReactDOM from 'react-dom';
import bgImage from '../images/physio.jpg';

// Demo styles, see 'Styles' section below for some notes on use.
import 'react-accessible-accordion/dist/fancy-example.css';

import { Link } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';

const IndexPage = () => (
  <Layout backgroundImage={bgImage}>
    <SEO
      title="Home"
      description="P3 Physio"
      keywords={[`physio`, `pilates`, `Peak performance physio`]}
    />
    <section>
      <h1>Welcome to P3 Physiotherapy</h1>
      <h2>Your Path to Wellness starts here</h2>
      <p>
        Whether you are a professional sports person looking to get back into
        training as soon as possible or maybe you would just like a bit less
        pain going about your day to day life.{' '}
      </p>
      <p>
        P3 Physio can offer individualised treatment and rehabilitation packages
        based on your needs.
      </p>
      <p>Use the options below to find out more:</p>
      <ul>
        <li>
          <a href="/physio">Physio</a>
        </li>
        <li>
          <a href="/pilates">Pilates</a>
        </li>
        <li>
          <a href="/run">Running analysis</a>
        </li>
      </ul>
      <p>
        Alternatively, <a href="/contact">get in touch today</a> to find out
        more.
      </p>
    </section>
  </Layout>
);

export default IndexPage;
